<template>
  <div class="loader-container">
    <div class="inner-container mb-16">
      <div class="loader"></div>
      <div class="loader-text text-h6 align-center text-center">{{ $t('invitation_page_loading_text') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvitationLoader',
}
</script>

<style lang="scss" scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #f5f5f5;
  overflow: hidden;
  position: relative;
  font-family: 'Assistant', sans-serif;
}

.inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 40px;
}

.loader {
  width: 48px; /* Reduced size */
  height: 48px;
  display: block;
  margin: 20px auto;
  position: relative;
  border: 3px solid #9155fd; /* Slightly thinner border */
  border-radius: 50%;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}

.loader::after {
  content: '';
  box-sizing: border-box;
  width: 6px; /* Thinner magnifying glass handle */
  height: 24px; /* Decreased height for a subtler look */
  background: #9155fd;
  transform: rotate(-45deg);
  position: absolute;
  bottom: -20px;
  left: 38px;
}

@keyframes animloader {
  0% {
    transform: translate(-10px, -10px);
  }
  25% {
    transform: translate(-10px, 10px);
  }
  50% {
    transform: translate(10px, 10px);
  }
  75% {
    transform: translate(10px, -10px);
  }
  100% {
    transform: translate(-10px, -10px);
  }
}

.loader-text {
  animation: textfade 2.5s ease-in-out infinite;
  color: #9155fd;
}

@keyframes textfade {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
