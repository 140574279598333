<template>
  <!-- guest response history -->
  <div>
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="6"
        class="pt-0"
      >
        <div class="d-flex text-center justify-center">
          <div v-if="guestResponse.status !== guestStatusMapping.WRONG_NUMBER">
            <div>
              <v-avatar
                class="v-avatar-light-bg primary--text"
                size="40"
              >
                <v-icon
                  color="primary"
                  size="27"
                >
                  {{ icons.mdiTooltipCheckOutline }}
                </v-icon>
              </v-avatar>
            </div>
            <div class="font-weight-bold">
              {{ getGuestStatus(guestResponse.status) }}
            </div>
          </div>

          <div
            v-if="guestResponse.status === guestStatusMapping.COMING"
            class="ms-5"
          >
            <div>
              <v-avatar
                class="v-avatar-light-bg primary--text"
                size="40"
              >
                <v-icon
                  size="27"
                  color="primary"
                >
                  {{ icons.mdiAccountMultiple }}
                </v-icon>
              </v-avatar>
            </div>
            <div class="font-weight-bold">
              {{ guestResponse.countOfGuests }}
            </div>
          </div>
          <div
            v-if="guestResponse.status === guestStatusMapping.WRONG_NUMBER"
            class="ms-5"
          >
            <div>
              <v-avatar
                class="v-avatar-light-bg primary--text"
                size="40"
              >
                <v-icon
                  color="primary"
                  size="27"
                >
                  {{ icons.mdiPhoneRemove }}
                </v-icon>
              </v-avatar>
            </div>
            <div class="font-weight-bold">
              {{ $t('wrongNumber') }}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="9">
        <v-btn
          class="font-weight-semibold text-base"
          block
          color="primary"
          @click="plansChanged"
        >
          {{ guestResponse.status === guestStatusMapping.WRONG_NUMBER ? $t('ChangedYourMind') : $t('PlanChanged') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { guestStatusMapping } from '@/composables/useGuestStatuses'
import { mdiAccountMultiple, mdiTooltipCheckOutline, mdiPhoneRemove } from '@mdi/js'
import { i18n } from '@/plugins/i18n/index'

export default {
  props: ['guestResponse'],
  setup(props, { emit }) {
    const getGuestStatus = status => {
      if (status === guestStatusMapping.COMING) {
        return i18n.t('Attending')
      }
      if (status === guestStatusMapping.NOT_COMING) {
        return i18n.t('NotAttending')
      }
    }

    const plansChanged = () => {
      emit('update:showPlansChanged', false)
    }

    return {
      getGuestStatus,
      guestStatusMapping,
      plansChanged,

      icons: {
        mdiTooltipCheckOutline,
        mdiAccountMultiple,
        mdiPhoneRemove,
      },
    }
  },
}
</script>
