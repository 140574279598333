<template>
  <v-menu
    top
    min-width="200"
    :elevation="$vuetify.theme.dark ? 10 : 5"
    offset-y
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }" >
      <v-btn
        fab
        small
        v-bind="attrs"
        v-on="on"
        class="language-btn"
        style="background-color: #5e35b1; color: white;"
      >
        <v-icon>{{ icons.mdiTranslate }}</v-icon>
      </v-btn>
    </template>

    <!-- Options List -->
    <v-list>
      <v-list-item-group
        :value="$i18n.locale"
        @change="updateActiveLocale"
      >
        <v-list-item
          v-for="locale in filteredLocales"
          :key="locale.locale"
          :value="locale.locale"
          class="locale-item"
        >
          <v-list-item-title>{{ locale.title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { loadLanguageAsync } from '@/plugins/i18n'
import { mdiTranslate } from '@mdi/js'

export default {
  props: {
    availableLocales: {
      type: Array,
      required: true,
    },
    defaultLocale: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiTranslate,
      },
      locales: [
        { title: 'English', locale: 'en' },
        { title: 'עברית', locale: 'he' },
        { title: 'Русский', locale: 'ru' },
        { title: 'العربية', locale: 'ar' },
      ],
    }
  },
  computed: {
    filteredLocales() {
      return this.locales.filter(locale => this.availableLocales.includes(locale.locale))
    },
  },
  mounted() {
    this.updateActiveLocale(this.defaultLocale)
  },
  methods: {
    updateActiveLocale(locale) {
      if (!locale) return

      this.isRtl = locale === 'ar' || locale === 'he'
      loadLanguageAsync(locale)
      this.$i18n.locale = locale
    },
  },
}
</script>

<style scoped>
.language-btn {
  box-shadow: none;
  transition: transform 0.2s ease;
}

.language-btn:hover {
  transform: scale(1.1);
  background-color: #512da8 !important; /* Deep Purple Darken-3 */
}

.locale-item {
  padding: 8px 16px;
  transition: background-color 0.2s ease;
}

.locale-item:hover {
  background-color: #f5f5f5; /* Light hover background */
}

.v-list-item-title {
  font-weight: 500;
  font-size: 16px;
}
</style>
