<template>
  <div v-if="!$store.state.tourGuideShow.value">
    <div :class="['floating-buttons', isRtl ? 'left' : 'right']">
      <v-btn v-if="preview" fab small @click="goBack" class="mb-0" style="background-color: #5e35b1; color: white">
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
      <LanguageSelectorV2
        v-if="availableLocales.length > 1"
        :available-locales="availableLocales"
        :default-locale="defaultLocale"
      />
    </div>
  </div>
</template>

<script>
import LanguageSelectorV2 from '@/components/LanguageSelectorV2.vue'
import { mdiClose } from '@mdi/js' // Updated icon import
import { mapState } from 'vuex'

export default {
  components: {
    LanguageSelectorV2,
  },
  props: {
    availableLocales: {
      type: Array,
      required: true,
    },
    defaultLocale: {
      type: String,
      required: true,
    },
    preview: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState({
      isRtl: state => state.appConfig.isRtl,
    }),
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
  },
  data() {
    return {
      icons: {
        mdiClose, // Updated icon reference
      },
    }
  },
}
</script>

<style scoped>
.floating-buttons {
  position: fixed;
  bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  gap: 10px;
}

.right {
  right: 10px;
}

.left {
  left: 10px;
}

.v-btn {
  margin-bottom: 12px;
  box-shadow: none;
  border-radius: 50%;
  transition: background-color 0.2s ease;
}

.v-btn:hover {
  background-color: #512da8 !important; /* Deep Purple Darken-3 */
}
</style>
