<template>
  <div class="invitation-container-all" :style="`${$vuetify.breakpoint.xsOnly ? 'background: white;' : ''}`">
    <InvitationPageLoader v-if="isInvitationPageLoading && !tourGuide" />
    <div v-show="!isInvitationPageLoading" :dir="$i18n.locale === 'ar' || $i18n.locale === 'he' ? 'rtl' : 'ltr'">
      <v-overlay v-show="isResponseLoading" opacity="0.35">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <!-- Invitation card -->
      <div class="d-flex justify-center mt-5">
        <div
          :style="`width: 100%; ${$vuetify.breakpoint.xsOnly ? '' : 'max-width: 390px;'}`"
          class="auth-v1 justify-center align-center"
          style="text-align: -webkit-center"
        >
          <v-card
            class="auth-card mb-10 rounded-xl"
            flat
            :class="{ 'ma-0 pa-0': $vuetify.breakpoint.mdAndDown }"
          >
            <v-row align="center" justify="center">
              <v-col cols="11">
                <div class="image-container -mt-10">
                  <PreloadedImage
                    @imageLoaded="handleImageLoaded"
                    :src="image ? image : require('@/assets/images/curly-heart.png')"
                    alt="invitation image"
                    class="invitation-image"
                  >
                  </PreloadedImage>
                </div>
              </v-col>
            </v-row>

            <v-form :disabled="isResponseLoading">
              <!-- if event not have image, display default image -->

              <!-- event Details -->
              <v-card-title class="d-flex align-center justify-center" style="word-break: break-word">
                <div class="font-weight-bold" style="font-size: 23px">
                  {{ names[$i18n.locale] || names[lang] || names[defaultLang] }}
                </div>
              </v-card-title>
              <v-card-subtitle class="d-flex flex-column align-center justify-center font-weight-semibold text-lg">
                <div class="mb-1">
                  {{ locationName }}
                </div>
                <div class="d-flex justify-center">
                  <span class="ma-3">{{ moment(date).format('DD/MM/YY') }}</span>
                  <span class="ma-3">{{ eventTime }}</span>
                </div>
              </v-card-subtitle>
              <!-- Guest response history -->
              <GuestResponse
                v-if="showPlansChanged && Object.keys(guestResponse).length > 0"
                :guest-response="guestResponse"
                :show-plans-changed.sync="showPlansChanged"
              />
              <!-- QA for guest -->
              <div v-if="!showPlansChanged">
                <v-card-text class="text-center">
                  <div class="font-weight-semibold mb-2 text-lg">
                    {{ $t('ifArriveQA') }}
                  </div>
                  <div class="d-flex justify-center align-center">
                    <v-radio-group v-model="guestStatus" class="small-radio mt-0 font-weight-bold" row>
                      <v-radio :label="$t('Yes')" :value="guestStatusMapping.COMING" class="ma-0 pa-0 mx-3"></v-radio>
                      <v-radio
                        :label="$t('No')"
                        :value="guestStatusMapping.NOT_COMING"
                        class="ma-0 pa-0 mx-3"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </v-card-text>
                <!-- Only show other questions if guest is coming -->
                <v-card-text v-if="guestStatus === guestStatusMapping.COMING">
                  <v-col cols="9" class="justify-center align-center" style="text-align: center">
                    <span class="font-weight-semibold text-lg">{{ $t('AmountPeople') }}</span>
                  </v-col>
                  <v-col cols="9" class="justify-center align-center mt-n3" style="text-align: center" dir="rtl">
                    <div class="d-flex justify-center align-center text-center">
                      <v-btn icon @click="numArrive < 20 ? numArrive++ : ''">
                        <v-avatar size="30" class="v-avatar-light-bg primary--text">
                          <v-icon color="primary">
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </v-avatar>
                      </v-btn>
                      <div class="mx-3 text-2xl font-weight-semibold">
                        {{ numArrive }}
                      </div>

                      <v-btn icon color="primary" @click="numArrive === 1 ? '' : numArrive--">
                        <v-avatar size="30" class="v-avatar-light-bg primary--text">
                          <v-icon>{{ icons.mdiMinus }}</v-icon>
                        </v-avatar>
                      </v-btn>
                    </div>
                  </v-col>
                  <!-- Meal option -->
                  <v-col v-if="meals.length > 0" cols="7" class="mt-n5">
                    <v-select
                      v-model="selectedMeal"
                      :items="meals"
                      :item-text="item => $t(item.name)"
                      :label="`${$t('Meal')}?`"
                      hide-details
                      clearable
                    ></v-select>
                  </v-col>
                  <!-- Transport option -->
                  <v-col v-if="transports.length > 0" cols="7" class="mt-0 pt-0">
                    <v-select
                      v-model="selectedTransport"
                      :items="transports"
                      :label="`${$t('Transport')}?`"
                      hide-details
                      clearable
                    ></v-select>
                  </v-col>
                  <!-- Guest comment -->
                  <v-col cols="8">
                    <v-textarea
                      v-model="guestComment"
                      :label="`${$t('comments').charAt(0).toUpperCase() + $t('comments').slice(1)}?`"
                      auto-grow
                      filled
                      rows="2"
                      row-height="20"
                      class="mb-0 mt-3"
                      :counter="40"
                      :maxlength="40"
                    ></v-textarea>
                  </v-col>
                </v-card-text>

                <!-- Send form -->
                <v-card-text>
                  <v-row align="center" justify="center">
                    <v-col cols="8" class="mt-0">
                      <v-btn
                        block
                        color="primary"
                        class="mt-4"
                        :disabled="guestStatus === null || preview"
                        @click="sendGuestResponse"
                      >
                        <span class="font-weight-semibold">
                          {{ $t('Send') }}
                        </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>

              <!-- Waze && google calendar -->
              <v-row style="text-align-last: center" align="center" justify="center" dir="rtl" v-if="guestStatus === guestStatusMapping.COMING">
                <!-- Waze -->
                <v-col cols="4" md="4" style="min-width: fit-content">
                  <v-card color="transparent" outlined flat @click="openNavigation">
                    <v-card-title class="align-center justify-center" style="margin-top: -6px">
                      <v-icon size="38">
                        {{ icons.mdiMapMarker }}
                      </v-icon>
                    </v-card-title>
                    <v-card-subtitle>
                      <span class="font-weight-semibold"> {{ $t('Navigation') }}</span>
                    </v-card-subtitle>
                  </v-card>
                </v-col>

                <!-- google calendar -->
                <v-col cols="4" md="4" style="min-width: fit-content">
                  <v-card color="transparent" outlined flat>
                    <v-card-title class="align-center justify-center mb-1">
                      <add-to-calendar
                        :title="names[$i18n.locale] || names[lang]"
                        :location="locationName"
                        :start="dateStart"
                        :end="dateStart"
                        inline-template
                      >
                        <div>
                          <google-calendar id="google-calendar">
                            <link
                              v-if="!tourGuide"
                              rel="stylesheet"
                              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
                            />
                            <button type="button">
                              <i
                                class="fa fa-calendar-check-o styleButton"
                                style="font-size: 1.4em; color: #8a8d93"
                              ></i>
                            </button>
                          </google-calendar>
                        </div>
                      </add-to-calendar>
                    </v-card-title>
                    <v-card-subtitle>
                      <span class="font-weight-semibold"> {{ $t('AddToCalendar') }}</span>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
              <div class="d-flex align-center justify-center" v-if="guestStatus !== guestStatusMapping.WRONG_NUMBER || !showPlansChanged">
                <v-icon size="20" class="me-1" color="primary">
                  {{ icons.mdiAlertOutline }}
                </v-icon>
                <v-btn color="transparent" text class="ma-0 pa-0" @click="wrongNumber">
                  <a target="_blank">
                    <span class="text-base primary--text"> {{ $t('wrongNumber') }}? </span>
                  </a>
                </v-btn>
              </div>
            </v-form>
          </v-card>
        </div>
      </div>
    </div>
    <InvitationBottomButtons :preview="preview" :available-locales="Object.keys(names)" :default-locale="lang" />
  </div>
</template>

<script>
import { loadLanguageAsync } from '@/plugins/i18n'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'

// eslint-disable-next-line object-curly-newline
import CountDownToEvent from '@/components/EventStatistics/CountDownToEvent.vue'
// eslint-disable-next-line object-curly-newline
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line object-curly-newline
import InvitationBottomButtons from '@/components/EventDetails/InvitationBottomButtons.vue'
import { guestStatusMapping } from '@/composables/useGuestStatuses'
import useSpecialMealsTypes from '@/composables/useSpecialMealsTypes'
import { useRouter } from '@core/utils'
import { mdiAlertOutline, mdiClose, mdiMapMarker, mdiMinus, mdiPlus } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import moment from 'moment'
import GuestResponse from './GuestResponse.vue'
import InvitationPageLoader from './InvitationPageLoader.vue'

export default {
  components: { AppBarI18n, CountDownToEvent, GuestResponse, InvitationBottomButtons, InvitationPageLoader },
  // eslint-disable-next-line vue/require-prop-types
  props: [
    'preview',
    'meals',
    'transports',
    'names',
    'locationName',
    'locationGeo',
    'date',
    'img',
    'lang',
    'defaultLang',
    'guestResponse',
    'tourGuide',
    'sendInviteeRequest',
    'sendWrongNumberRequest',
  ],
  setup(props) {
    const { route, router } = useRouter()
    const image = ref(props.img)
    const { isRtl } = useAppConfig()
    const loadStartTime = Date.now()
    const minimumLoadingTime = 1000

    watch(
      () => props.img,
      newVal => {
        image.value = newVal
      },
    )
    const { mealsTypes } = useSpecialMealsTypes()
    const isLoadingInvitationImage = ref(true)
    const guestStatus = ref()
    const numArrive = ref(1)
    const selectedMeal = ref('')
    const selectedTransport = ref([])
    const guestComment = ref('')
    const isResponseLoading = ref(false)

    const eventDate = new Date(props.date)
    const eventHour = eventDate.getHours() < 10 ? `0${eventDate.getHours()}` : eventDate.getHours()
    const eventMinute = eventDate.getMinutes() < 10 ? `0${eventDate.getMinutes()}` : eventDate.getMinutes()
    const eventTime = `${eventHour}:${eventMinute}`

    // The event date on google calendar
    const dateStart = new Date(
      eventDate.getFullYear(),
      eventDate.getMonth(),
      eventDate.getDate(),
      eventDate.getHours(),
      eventDate.getMinutes(),
    )

    const showPlansChanged = ref()

    const isInvitationPageLoading = computed({
      get: () => {
        const checkIfPropsLoaded = Object.keys(props).every(prop => props[prop] !== undefined)
        return checkIfPropsLoaded || isLoadingInvitationImage.value
      },
    })

    const handleImageLoaded = () => {
      const loadEndTime = Date.now()
      const timeSpentLoading = loadEndTime - loadStartTime
      const remainingTime = minimumLoadingTime - timeSpentLoading

      if (remainingTime > 0) {
        setTimeout(() => {
          isLoadingInvitationImage.value = false
        }, remainingTime)
      } else {
        isLoadingInvitationImage.value = false
      }
    }

    const openNavigation = () => {
      if (props.tourGuide) return
      const queryParams = {
        lang: props.lang,
        location: props.locationGeo,
        invitation: true,
        preview: props.preview,
      }

      // If it guest invitation, take event and guest details from the token
      if (!props.preview) {
        router.push({ name: 'guest-navigation', params: { guestID: route.value.params.id } })
      } else {
        // If it preview invitation, take event details from props
        router.push({ name: 'preview-navigation', query: queryParams })
      }
    }

    // Wrong number
    const wrongNumber = async () => {
      if (props.preview) return
      isResponseLoading.value = true
      await props.sendWrongNumberRequest()
    }
    const sendGuestResponse = async () => {
      isResponseLoading.value = true

      if (guestStatus.value === guestStatusMapping.NOT_COMING) {
        numArrive.value = 0
        selectedMeal.value = 0
        selectedTransport.value = [0]
        guestComment.value = ''
      }

      await props.sendInviteeRequest({
        status: guestStatus.value,
        countOfGuests: numArrive.value,
        guestSpecialMeals: [selectedMeal.value],
        guestTransports: [selectedTransport.value],
        comment: guestComment.value,
      })
    }
    const loadLangPage = () => {
      loadLanguageAsync(props.lang)
      isRtl.value = props.lang === 'ar' || props.lang === 'he'
    }
    loadLangPage()
    const loadLastGuestResponse = () => {
      isResponseLoading.value = false
      // if guest already response
      if (props.guestResponse && Object.keys(props.guestResponse).length > 0) {
        guestStatus.value = props.guestResponse.status
        numArrive.value = props.guestResponse.countOfGuests > 0 ? props.guestResponse.countOfGuests : 1
        selectedTransport.value = props.guestResponse.guestTransports[0]?.name
        // eslint-disable-next-line operator-linebreak
        selectedMeal.value =
          props.guestResponse.specialMeal !== mealsTypes.NO_NEED_TO_ASK
            ? props.guestResponse.specialMeals[0]?.value
            : ''
        showPlansChanged.value = true
      }
    }
    loadLastGuestResponse()

    watch(
      () => props.guestResponse,
      () => loadLastGuestResponse(),
    )

    return {
      image,
      moment,
      guestStatus,
      numArrive,
      dateStart,
      eventTime,
      sendGuestResponse,
      selectedMeal,
      selectedTransport,
      guestComment,
      wrongNumber,
      loadLastGuestResponse,
      showPlansChanged,
      openNavigation,
      mealsTypes,

      icons: {
        mdiMinus,
        mdiPlus,
        mdiAlertOutline,
        mdiClose,
        mdiMapMarker,
      },
      isInvitationPageLoading,
      handleImageLoaded,
      guestStatusMapping,
      isResponseLoading,
    }
  },
}
</script>

<style lang="scss" scoped>
.small-radio label {
  font-size: 10px !important;
  padding-left: 0px !important;
}

.styleButton {
  color: #8a8d93 !important;
}
.v-application a {
  color: #8a8d93 !important;
}
.fa {
  color: #8a8d93 !important;
}

.invitation-image {
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
}
.image-container {
  position: relative;
  width: 100%;
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 0px;
  margin-left: 0px;
}
::v-deep .v-input--selection-controls__input {
  margin-left: 3px !important;
  margin-right: 3px !important;
}
::v-deep .v-input--selection-controls .v-radio > .v-label {
  font-size: 19px;
}

.invitation-container-all {
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  height: 100%;
}

.auth-card {
  min-height: 96vh;
}
</style>
